
import NavigationBar from '../common/NavigationBar.vue';
import Footer from '../common/Footer.vue';

export default {
  name: "Home",
  components: {
    NavigationBar,
    Footer,
  },
  computed: {
    signupUrl(){
      return `https://login.microsoftonline.com/common/adminconsent?client_id=${CLIENT_ID}`;
      // https://graph.microsoft.com/Mail.Send https://graph.microsoft.com/User.Read https://graph.microsoft.com/User.Read.All https://graph.microsoft.com/User.ReadBasic.All
      // https://login.microsoftonline.com/{tenant}/v2.0/adminconsent
      //   ?client_id=6731de76-14a6-49ae-97bc-6eba6914391e
      // login.microsoftonline.com/organizations/oauth2/v2.0/authorize
    }
  }
};
