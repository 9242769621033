
import axios from 'axios';
import _ from 'lodash';
import PricingCard from '../common/PricingCard.vue';
import NavigationBar from '../common/NavigationBar.vue';

export default {
  name: 'Pricing',
  components: {
    PricingCard,
    NavigationBar,
  },
  data() {
    return {
      clientId: '',
      user: {},
    };
  },
  async created() {
    this.clientId = this.$route.query.c;
    if (!this.clientId){
      this.$alert("We couldn't identify you");
      window.open('https://outlook.office.com/mail/')
    }
    await this.getUser()

    if (_.isEmpty(this.user)){
      this.$alert("We couldn't identify you");
      return
    }
  },

  methods: {
    async getUser() {
      try {
        const res = await axios.get(`/user/id/${this.clientId}`);
        this.user = res.data.user
      } catch(err) {
        console.error(err);
      }
    },

    allowPurchase(){
      return !_.isEmpty(this.user) && this.user.plan != 'free'
    },

    onApprove(plan){
      this.$alert("Thank you for your purchase");
      this.user.plan = plan;
    }
  },

  computed: {
    allowPurchasePro(){
      return !_.isEmpty(this.user) && !['pro', 'master'].includes(this.user.plan)
    },
    allowPurchaseMaster(){
      return !_.isEmpty(this.user) && this.user.plan != 'master'
    }
  }
}
