
  import axios from 'axios';
  export default {
    name: 'PricingCard',
    props: {
      paypalEnabled: Boolean,
      paypalContainer: String,
      paypalPlan: String,
      plan: String,
      title: String,
      features: Array,
      price: String,
      oldPrice: String,
      cardColor: String,
      buttonColor: String,
      clientId: String
    },

    data() {
      return {
          showPayPalBtn: false,
      };
    },

    methods: {
      onBtnClick(){
          if (this.paypalEnabled) {
            this.showPayPalBtn = true
          } else {
            window.open('https://outlook.office.com/mail/')
          }
          axios.get(`/payment/btn/${this.plan}`);
        },

      initPayPal(){
        const that = this
        const payPalOptions = {
          'plan_id': this.paypalPlan,
          'custom_id': this.clientId,
        };
        paypal.Buttons({
          createSubscription(data, actions) {
              return actions.subscription.create(payPalOptions);
          },
          onApprove(data) {
            that.$emit("onApprove", that.plan)
            // console.log(data)
            that.showPayPalBtn = false
            // window.open('https://outlook.office.com/mail/')
          }
        }).render(`#${this.paypalContainer}`)
      }
    },
    mounted(){
      console.log(this.paypal)
      if (this.paypalPlan) {
        this.initPayPal();
      }
    }
  }
  